.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
input:read-only, textarea:read-only {
  background-color: #edebeb;
  color: '#000';
}
.some {
  height: '18px';
  background-color: aqua;
}
.InovuaReactDataGrid {
  background-color:aliceblue !important;
}
.InovuaReactDataGrid__header {
  background-color: rgb(106,132,157) !important;
  color:white;
}
.InovuaReactDataGrid__row--odd {
  background-color: rgb(240, 242, 245) !important;
  color: rgb(0,0,0) !important;
}
.InovuaReactDataGrid__row--even {
  background-color: rgb(255,255,255) !important;
  color: rgb(0,0,0) !important;
}
.InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--even {
  color: rgb(0,0,0) !important;
}
.InovuaReactDataGrid__row--active.InovuaReactDataGrid__row--odd {
  color: rgb(0,0,0) !important;
}
.Inovua-react-pagination-toolbar--theme-default-light {
  background-color: gainsboro !important; 
}
.InovuaReactDataGrid__row {
  color: rgb(0,0,0) !important;
}
.InovuaReactDataGrid__column-header {
  line-height: 1.1 !important;
}
.InovuaReactDataGrid__cell__content {
  user-select: text;
}
.mainContainer {
  height: 100%;
  width: 100%; 
  
}
.main {
  background: #61dafb;
  height: calc(100% - 0px);
  position: relative;
  width: 100%;
}
.subForm {
  background: 'red';
  height: calc(100% - 0px);
  position: relative;
  width: 100%;
}

